<template>
  <div v-if="Object.keys(value).length">
    <h4 v-if="value.listener">{{ value.listener }}</h4>
    <template v-if="value.settings.length">
      <div
        v-for="(settingSet, settingIndex) in value.settings"
        :key="settingIndex"
      >
        <v-simple-table class="mb-2">
          <thead>
            <tr>
              <th class="text-left">#{{ settingIndex }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(setting, index) in Object.keys(settingSet)"
              :key="index"
              class="highlighter"
            >
              <td class="font-weight-medium">{{ setting }}:</td>
              <td>{{ settingSet[setting] }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
    </template>
  </div>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;

export default {
  name: "BFFResourceListener",
  props: {
    value: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      listeners: [],
      loading: false
    };
  }
};
</script>

<style>
.highlighter:hover {
  background-color: #d6d3d3 !important;
}
</style>
