<template>
  <v-container class="container" fluid>
    <Debugger />
  </v-container>
</template>

<script>
import Debugger from "@/components/BFFresources/Debugger";

export default {
  name: "BFFDebugger",
  components: { Debugger }
};
</script>
