<template>
  <div>
    <div class="mb-2 d-flex justify-end align-center">
      <v-btn color="primary" @click="resetTimer">{{ $t("refresh") }}</v-btn>
      <v-select
        :items="periods"
        v-model="selectedPeriod"
        hide-details
        @change="resetTimer"
        :label="$t('debugger.timer')"
        class="w-10 ml-4"
        :disabled="noRepeat"
      ></v-select>
      <v-switch
        v-model="noRepeat"
        :label="$t('debugger.noRepeat')"
        class="ml-2"
      ></v-switch>
      <v-select
        :items="limits"
        v-model="selectedLimit"
        hide-details
        :label="$t('debugger.limit')"
        class="w-10 ml-6"
      ></v-select>
    </div>
    <div v-if="loading && !flow.length" class="text-center my-4">
      <v-progress-circular :size="50" color="primary" indeterminate />
    </div>
    <div v-else class="my-4">
      <div v-if="flow.length">
        <div v-for="(entry, index) in flow" :key="index" class="mb-12">
          <h3 class="font-black mb-2">
            {{ entry.messageId }} ({{
              $services.dateTime.formatDateTimeFromEPOC(
                getSafeTimestamp(entry.messageId)
              )
            }})
          </h3>
          <div
            v-for="(version, vindex) in getParsedData(entry.values.payload)"
            :key="vindex"
            class="my-1 highlighter"
          >
            <span>{{ version.label }}:</span>
            <span
              v-if="
                version.data && (version.data.length || !isNaN(version.data))
              "
              class="grey--text ml-2"
              >{{ version.data }}</span
            >
            <template v-else>
              <SingleObjectView v-if="version.data" :obj="version.data" />
            </template>
          </div>
        </div>
      </div>
      <div v-else>{{ $t("debugger.noFlow") }}</div>
    </div>
  </div>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;
import SingleObjectView from "@/components/BFFresources/SingleObjectView";

export default {
  name: "DebuggerListen",
  components: { SingleObjectView },
  props: {
    openDialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      flow: [],
      loading: false,
      selectedPeriod: 5000,
      timerID: null,
      selectedLimit: 100,
      noRepeat: false
    };
  },
  mounted() {
    this.listen();
  },
  watch: {
    openDialog(val) {
      if (!val && this.timerID) {
        clearTimeout(this.timerID);
        this.timerID = null;
      }
    },
    noRepeat(val) {
      if (val && this.timerID) {
        clearTimeout(this.timerID);
        this.timerID = null;
      }
      if (!val) {
        this.listen(true);
      }
    }
  },
  computed: {
    periods() {
      return [
        { value: 5000, text: " 5 sec" },
        { value: 10000, text: "10 sec" },
        { value: 30000, text: "30 sec" }
      ];
    },
    limits() {
      return [
        { value: 20, text: "20" },
        { value: 50, text: "50" },
        { value: 100, text: "100" }
      ];
    }
  },
  methods: {
    async listen(again = false) {
      this.loading = true;

      let filter = null;
      if (again && this.flow.length) {
        let lastDate = this.flow[this.flow.length - 1].messageId
          .split("-")
          .shift();
        filter = new Date(lastDate * 1).toISOString();
      }

      try {
        const result = await callAPI({
          url: `${this.$store.state.idp_api_url}/channels/bff-event/messages${
            filter ? "?filter=createdAt:gte:" + filter : ""
          }`,
          method: "GET"
        });
        this.flow.push(...result.data);
        const overLimit = this.flow.length - this.selectedLimit;
        if (overLimit > 0) {
          this.flow.splice(0, overLimit);
        }
      } catch (error) {
        this.errorSnackbar(error);
      }
      this.loading = false;
      if (!this.noRepeat) {
        this.timerID = setTimeout(() => this.listen(true), this.selectedPeriod);
      }
    },
    resetTimer() {
      clearTimeout(this.timerID);
      this.listen(true);
    },
    getSafeTimestamp(timestamp) {
      if (!timestamp) {
        return null;
      }
      return timestamp
        .toString()
        .split("-")
        .shift();
    },
    getParsedData(rawItem) {
      const parsedVersions = [];

      if (!rawItem) {
        return parsedVersions;
      }

      let item;
      try {
        item = JSON.parse(rawItem);
      } catch {
        return parsedVersions;
      }

      const versions = Object.entries(item);
      if (versions.length) {
        versions.forEach(version => {
          parsedVersions.push({
            label: version[0],
            data: version[1]
          });
        });
      }
      return parsedVersions;
    }
  }
};
</script>

<style>
.w-10 {
  max-width: 10%;
}
</style>
