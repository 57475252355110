<template>
  <v-card>
    <v-card-title>
      {{ $t("debugger.resources") }}
      <v-btn
        small
        class="elevation-4 ml-6"
        color="primary"
        v-on:click="listen_click()"
      >
        {{ $t("debugger.listen") }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('debugger.resourceNameSearch')"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="resources"
      :items-per-page="10"
      :search="search"
      :loading="loading"
      :expanded.sync="expanded"
      :single-expand="true"
      item-key="resource"
      show-expand
      :loading-text="$t('debugger.loading')"
      class="mt-4"
    >
      <template v-slot:item.resource="{ item }">
        <span class="font-weight-bold">{{ item.resource }}</span>
      </template>

      <template v-slot:item.lastTransaction="{ item }">
        <div
          v-for="(transaction, index) in getParsedData(item.lastTransaction)"
          :key="index"
          class="my-1 highlighter"
        >
          <span>{{ transaction.label }}:</span>
          <span class="grey--text ml-2">{{
            $services.dateTime.formatDateTimeFromEPOC(
              getSafeTimestamp(transaction.data)
            )
          }}</span>
        </div>
      </template>

      <template v-slot:item.coreVersion="{ item }">
        <div
          v-for="(version, index) in getParsedData(item.coreVersion)"
          :key="index"
          class="my-1 highlighter"
        >
          <span>{{ version.label }}:</span>
          <span class="grey--text ml-2">{{ version.data }}</span>
        </div>
      </template>

      <template v-slot:item.origin="{ item }">
        <v-icon small color="blue darken-2" class="mr-1">mdi-link</v-icon>
        <a :href="item.origin" target="_blank">{{ item.origin }}</a>
      </template>

      <template v-slot:item.bffMaster="{ item }">
        <v-icon v-if="item.bffMaster" color="green darken-2"
          >mdi-check-outline</v-icon
        >
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <BFFResourceTransactions
            :value="item"
            @listener::added="getResources"
          />
        </td>
      </template>
    </v-data-table>

    <!-- debugger listen dialog -->
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="1400"
      persistent
      v-model="openDialog"
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <span>{{ $t("debugger.listen") }}</span>
          <v-spacer />
          <v-btn text @click="closeTheDialog">{{ $t("close") }}</v-btn>
        </v-toolbar>
        <v-card-text class="mt-4">
          <DebuggerListen :openDialog="openDialog" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;
import BFFResourceTransactions from "@/components/BFFresources/BFFResourceTransactions";
import DebuggerListen from "@/components/BFFresources/DebuggerListen";

export default {
  name: "Debugger",
  components: { BFFResourceTransactions, DebuggerListen },
  data() {
    return {
      resources: [],
      search: "",
      loading: false,
      expanded: [],
      openDialog: false
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("debugger.resourceName"),
          width: "10%",
          sortable: true,
          value: "resource"
        },
        {
          text: this.$t("debugger.lastTransaction"),
          width: "20%",
          sortable: false,
          value: "lastTransaction"
        },
        {
          text: this.$t("debugger.resourceVersion"),
          width: "10%",
          sortable: false,
          value: "resourceVersion"
        },
        {
          text: this.$t("debugger.coreVersion"),
          width: "15%",
          sortable: false,
          value: "coreVersion"
        },
        {
          text: this.$t("debugger.clusterCode"),
          width: "10%",
          sortable: true,
          value: "clusterCode"
        },
        {
          text: this.$t("debugger.origin"),
          width: "20%",
          sortable: false,
          value: "origin"
        },
        {
          text: this.$t("debugger.bffMaster"),
          width: "10%",
          sortable: true,
          value: "bffMaster"
        },
        {
          text: "",
          width: "5%",
          sortable: false,
          value: "data-table-expand"
        }
      ];
    }
  },
  mounted() {
    this.getResources();
  },
  methods: {
    async getResources() {
      this.resources = [];
      this.loading = true;
      try {
        const result = await callAPI({
          url: `${this.$store.state.idp_api_url}/bff-resources?fields=status`,
          method: "GET"
        });
        const entries = Object.entries(result?.data?.status);
        if (entries.length) {
          entries.forEach(entry => {
            this.resources.push({ ...entry[1], resource: entry[0] });
          });
        }
      } catch (error) {
        this.errorSnackbar(error);
      }
      this.loading = false;
    },
    getParsedData(item) {
      const parsedVersions = [];

      if (!item) {
        return parsedVersions;
      }

      const versions = Object.entries(item);
      if (versions.length) {
        versions.forEach(version => {
          parsedVersions.push({
            label: version[0],
            data: version[1]
          });
        });
      }
      return parsedVersions;
    },
    getSafeTimestamp(timestamp) {
      if (!timestamp) {
        return null;
      }
      return timestamp
        .toString()
        .split("-")
        .shift();
    },
    // expand(item) {
    //   if (item) {
    //     this.expanded = [item];
    //     this.saveExpanded(item.resource);
    //     this.item = item;
    //   }
    // },
    listen_click() {
      this.openDialog = true;
    },
    closeTheDialog() {
      this.openDialog = false;
    }
  }
};
</script>

<style scoped>
.highlighter:hover {
  background-color: grey;
}
tr.v-data-table__expanded__row {
  background: #3c3c3c !important;
}
</style>
