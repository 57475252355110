<template>
  <div v-if="loading" class="text-center my-4">
    <v-progress-circular :size="50" color="primary" indeterminate />
  </div>
  <div v-else class="my-4">
    <div class="d-flex mb-2 justify-space-between">
      <h3>{{ $t("debugger.listeners") }}</h3>
      <v-btn small class="elevation-4" color="primary" v-on:click="add_click()">
        {{ $t("add_button") }}
      </v-btn>
    </div>
    <div v-if="listeners.length">
      <v-container>
        <v-row>
          <v-col v-for="(listener, index) in listeners" :key="index">
            <BFFResourceListener :value="listener" />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div v-else>{{ $t("debugger.noListeners") }}</div>

    <!-- add command dialog -->
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      persistent
      v-model="openDialog"
    >
      <v-card>
        <v-toolbar color="primary" dark
          >{{ $t("debugger.addTransaction") }}
        </v-toolbar>
        <v-card-text class="mt-4">
          <div class="mt-4" :class="!modalContent.cmd ? 'red lighten-5' : null">
            <v-text-field
              :label="$t('debugger.cmd')"
              v-model="modalContent.cmd"
              type="text"
              maxlength="256"
              hide-details
              disabled
            />
          </div>

          <div
            class="mt-4"
            :class="!modalContent.resourceCode ? 'red lighten-5' : null"
          >
            <v-text-field
              :label="$t('debugger.resourceCode')"
              v-model="modalContent.resourceCode"
              type="text"
              maxlength="256"
              hide-details
              :disabled="modalContent.resourceCode ? true : false"
            />
          </div>

          <div class="mt-6">
            <h4 class="mb-2">{{ $t("debugger.listeners") }}:</h4>
            <AceEditor
              v-model="modalContent.listeners"
              @init="editorInit"
              lang="json"
              theme="textmate"
              width="100%"
              height="200px"
              :options="{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                fontSize: 14,
                highlightActiveLine: true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 2,
                showPrintMargin: false,
                showGutter: true
              }"
            />
          </div>

          <!-- errors -->
          <div class="d-flex flex-row" v-if="error">
            <span class="subtitle-1 mb-4 red--text"
              >{{ $t("form_invalid_text") }}: {{ error }}</span
            >
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="closeTheDialog" :disabled="loadingSave">{{
            $t("close")
          }}</v-btn>
          <v-btn
            text
            color="teal lighten-2"
            @click="addTransaction"
            :loading="loadingSave"
            >{{ $t("save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;
import BFFResourceListener from "@/components/BFFresources/BFFResourceListener";
import AceEditor from "vuejs-ace-editor";

export default {
  name: "BFFResourceTransactions",
  components: { BFFResourceListener, AceEditor },
  props: {
    value: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      listeners: [],
      loading: false,
      loadingSave: false,
      openDialog: false,
      error: null,
      modalContent: {
        cmd: "setListeners",
        resourceCode: null,
        listeners: null
      }
    };
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        this.modalContent.resourceCode = val?.resource || null;
        this.getResourceTransactions();
      }
    }
  },
  methods: {
    async getResourceTransactions() {
      this.listeners = [];

      if (!this.value?.resource || !this.value?.lastTransaction?.tid) {
        return;
      }

      this.loading = true;
      try {
        const result = await callAPI({
          url: `${this.$store.state.idp_api_url}/bff-resources?fields=listeners&filter=resourceCode:eq:${this.value.resource}`,
          method: "GET"
        });
        const entries = Object.entries(
          result?.data?.listeners[this.value.resource] || {}
        );
        if (entries.length) {
          entries.forEach(entry => {
            this.listeners.push({ settings: entry[1], listener: entry[0] });
          });
        }
      } catch (error) {
        this.errorSnackbar(error);
      }
      this.loading = false;
    },
    add_click() {
      this.openDialog = true;
    },
    closeTheDialog() {
      this.openDialog = null;
      this.error = null;
    },
    editorInit: function() {
      require("brace/ext/language_tools");
      require("brace/mode/html");
      require("brace/mode/json");
      require("brace/mode/less");
      require("brace/theme/textmate");
    },
    async addTransaction() {
      this.error = null;

      if (
        !this.modalContent.cmd ||
        !this.modalContent.resourceCode ||
        !this.modalContent.listeners
      ) {
        return;
      }

      let parsedObject;

      try {
        parsedObject = JSON.parse(this.modalContent.listeners);
      } catch {
        this.error = "Listerners";
        return;
      }

      const data = {
        ...this.modalContent,
        listeners: parsedObject
      };

      try {
        this.loadingSave = true;
        const response = await callAPI({
          url: `${this.$store.state.idp_api_url}/bff-transactions`,
          method: "POST",
          data
        });
        if (response.status == 204 || response.status == 200) {
          this.getResourceTransactions();
          this.$emit("listener::added");
          this.closeTheDialog();
        }
      } catch (err) {
        const data = err?.response?.data;
        if (data.statusCode == 400) {
          this.error = this.$t(data.validation.keys.join(", "));
        } else {
          this.errorSnackbar(err);
        }
      } finally {
        this.loadingSave = false;
      }
    }
  }
};
</script>
